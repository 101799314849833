import React from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'

const Radio = (props) => {
    const onChange = function (val) {
        props.onChange(val)
    }

    return (
        <div className={"flex " + (props.grid && !isMobile ? "mt-1 grid grid-cols-2 gap-1 " : props.inline ? "flex-row gap-4 " : "flex-col gap-2 ") + (props.className || "")}>
            {props.options.map(option => (
                <div key={option.key} className="flex">
                    <div className="flex items-center h-5">
                        <input id={props.id + "_" + option.key} type="radio" value={option.key} name={props.name} className="w-4 h-4" onChange={e => onChange(e.target.value)} defaultChecked={props.defaultValue === option.key} disabled={props.disabled || false} />
                    </div>
                    <div className="ml-1.5 text-sm">
                        <label htmlFor={props.id + "_" + option.key} className="font-medium text-gray-900">{option.text}
                            {option.helper && <p id={props.id + "_helper"} className="text-xs font-normal text-gray-500">{option.helper}</p>}
                        </label>
                    </div>
                </div>
            ))}
        </div>
    )
}

Radio.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    grid: PropTypes.number,
    inline: PropTypes.bool
}

export default Radio