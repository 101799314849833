import React, { useState } from 'react'
import { CommonFormTextInput, CommonFormButton, CommonFormSelect, CommonFormDateInput, CommonFormCurrencyInput, CommonFormEmailInput, CommonError } from '../../../index'
import application from '../../../../apis/application'
import { useNavigate } from 'react-router-dom'
import MixPanel from '../../../../config/mixpanel'

const CustomerDetails = (props) => {
    const [data, setData] = useState({})
    let [error, setError] = useState(null)
    const navigate = useNavigate()

    function onDataChange(property, value) {
        setData({ ...data, [property]: value })
    }

    async function onSubmit() {
        setError(null)

        const response = await application.new(data)
        if (response.status === 200) {
            const eligibility = response.data?.data?.eligible
            if (eligibility === true) props.next(2, response.data?.data?.application_number)
            else if (eligibility === false) props.next(3, response.data?.data?.application_number)
            else props.next(1, response.data?.data?.application_number)
            MixPanel.track(MixPanel.types.agent.application.form1)
        } else if (response.status === 401) {
            navigate("/agent/auth")
        } else {
            setError(response.error)
        }
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Eligibility Check</h6>
            <hr className="mt-3 text-gray" />
            <p className="mt-6">Fill the customer's details to know their Loan Eligibility.</p>
            {error && <CommonError error={error} className="mt-2 col-span-3" />}
            <form className="mt-4 grid grid-cols-6 gap-4" autoComplete="off">

                <CommonFormTextInput id="sk-eg-first-name" label="First Name and Middle Name (As Per PAN)*" maxLength="32" minLength="3" onChange={(v) => onDataChange("first_name", v)} className="col-span-6 lg:col-span-3 xl:col-span-2" regex="[^A-Za-z\. ]" />

                <CommonFormTextInput id="sk-eg-last-name" label="Last Name (As per PAN)*" maxLength="32" minLength="3" onChange={(v) => onDataChange("last_name", v)} className="col-span-6 lg:col-span-3 xl:col-span-2" regex="[^A-Za-z\. ]" />

                <CommonFormTextInput id="sk-eg-mobile" label="Mobile Number*" type="tel" minLength="10" maxLength="10" onChange={(v) => onDataChange("mobile", v)} className="col-span-6 lg:col-span-3 xl:col-span-2 xl:col-start-1" />

                <CommonFormDateInput id="sk-eg-dob" label="Date of Birth*" onChange={(v) => onDataChange("dob", v)} className="col-span-6 lg:col-span-3 xl:col-span-2" />

                <CommonFormSelect id="sk-eg-gender" label="Gender*" onChange={(v) => onDataChange("gender", v)} className="col-span-6 lg:col-span-3 xl:col-span-2 xl:col-start-1" >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                </CommonFormSelect>

                <CommonFormCurrencyInput id="sk-eg-income" label="Monthly Income*" min="1000" max="999999" onChange={(v) => onDataChange("monthly_income", v)} className="col-span-6 lg:col-span-3 xl:col-span-2" />

                <CommonFormEmailInput id="sk-eg-email" type="email" label="E-mail Id*" maxLength="64" onChange={(v) => onDataChange("email", v)} className="col-span-full xl:col-span-4 xl:col-start-1" />

                <CommonFormCurrencyInput id="sk-eg-cost" label="Itinerary Cost*" min="5000" max="500000" onChange={(v) => onDataChange("amount", v)} className="col-span-full xl:col-span-4 xl:col-start-1" />

                <p className="col-span-full xl:col-span-4 xl:col-start-1">This eligibility check won't affect customer's credit score.</p>
                <CommonFormButton id="sk-eg-submit" text="Continue" className="col-span-full xl:col-span-4 xl:col-start-1 mt-3" onClick={onSubmit} />
            </form>
        </div>
    )
}

export default CustomerDetails