import React from 'react'
import { Link } from 'react-router-dom'
import { IconContext } from 'react-icons'

const menuSubItem = (props) => {
    return (
        <Link to={props.link ? props.link : "#"} onClick={props.closeNav} >
            <div className={"flex flex-row items-center p-3 cursor-pointer " + (props.active ? "bg-white rounded-s-full ml-16 pl-4" : "pl-20 hover:bg-sk-blue-select")}>
                <p className="mx-2">
                    {props.logo && typeof props.logo === "string" && <img className={"h-5 w-5 " + (props.active && "invert")} src={props.logo} alt="sub menu icon" />}
                    {props.logo && typeof props.logo === "object" && <IconContext.Provider value={{ className: "h-5 w-5 " + (!props.active && "invert") }}>{props.logo}</IconContext.Provider>}
                </p>
                <p className={"grow text-xs uppercase " + (props.active === true ? "font-bold text-black" : "font-medium text-white")}>{props.text}</p>
            </div>
        </Link>
    )
}

export default menuSubItem