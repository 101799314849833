import callapi from "./callapi"
import endpoints from "../config/endpoints"

const Static = {
    searchAirports: async function ({ type, search }) {
        return await callapi({
            url: endpoints.static.searchAirports,
            method: "get",
            params: {
                type,
                search
            }
        })
    },
    calcEMI: async function ({ amount }) {
        return await callapi({
            url: endpoints.static.calcEMI,
            method: "post",
            data: { amount }
        })
    }
}

export default Static