import React from 'react'
import PropTypes from 'prop-types'

const popup = (props) => {
    return (
        <div className="z-40 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto bg-gray-900 bg-opacity-50 flex items-center justify-center">
            <div className={"bg-white rounded-2xl p-6 fade-in-top max-h-full overflow-y-scroll " + (props.className || "") + (props.dimensions ? props.dimensions : props.wide ? "w-full lg:w-2/3 xl:w-1/2" : "w-80")}>
                <p className="text-right">
                    <img src="/assets/icons/cross.svg" alt="cross" className="inline w-6 h-6 p-1.5 rounded-full bg-gray-100 opacity-50 cursor-pointer" onClick={() => props.onCancel && typeof props.onCancel === "function" && props.onCancel()} />
                </p>
                {props.icon && <p className="text-center">
                    {typeof props.icon === "string" ? <img src={props.icon} alt="icon" className="w-9 h-9 inline" /> : props.icon}
                    <br />
                </p>}
                <div className="text-center mt-2">{props.children}</div>
                <p className="text-center mt-8 flex justify-around">
                    <button className="w-24 rounded-xl bg-sk-blue text-white font-medium text-sm py-2" onClick={() => !props.disableOk && props.onOk && typeof props.onOk === "function" && props.onOk()}>OK</button>
                    {props.showCancel === true && <button className="w-24 rounded-xl border-2 border-gray-300 text-gray-500 font-medium text-sm py-2" onClick={() => props.onCancel && typeof props.onCancel === "function" && props.onCancel()}>CANCEL</button>}
                </p>
            </div>
        </div>
    )
}

popup.propTypes = {
    className: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    disableOk: PropTypes.bool,
    icon: PropTypes.string,
    showCancel: PropTypes.bool,
    wide: PropTypes.bool,
    sticky: PropTypes.bool,
    dimensions: PropTypes.string
}

export default popup