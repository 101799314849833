import application from './application'
import insurance from './insurance'
import auth from './auth'
import customer from './customer'
import paymentCollection from './payment-collection'
import statik from './static'
import transactions from './transactions'
import wallet from './wallet'
import backoffice from './backoffice'
import util from './util'
import dashboards from './dashboards'
import support from './support'
import onboarding from './onboarding'
import agentOnboarding from './agent-onboarding'

const API = {
    application, insurance, auth, customer, paymentCollection, statik, transactions, wallet, backoffice, util, dashboards, support, onboarding, agentOnboarding
}

export default API