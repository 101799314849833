import React, { useEffect, useState } from 'react'
import { CommonTooltip } from '..'
import { IoIosInformationCircleOutline } from "react-icons/io"
import API from '../../apis'

const SummaryMetrics = (props) => {
    const [data, setData] = useState(props.data || null)

    useEffect(() => {
        const fetchSummary = async function () {
            const response = await API.backoffice.summary(props.type)
            if (response.status === 200 && response.data?.data) {
                setData(response.data.data)
            }
        }

        if (props.type) fetchSummary()
    }, [props.type])

    return (
        <div className="flex">
            {data && <div className={"flex-1 flex space-x-4 *:flex-1 *:p-4 *:bg-sk-blue *:bg-opacity-10 *:text-center *:text-xs *:font-bold *:rounded-xl " + (props.className || "")}>
                {Object.keys(data.display).map((x, i) =>
                    <p key={i}>
                        <span className="text-sk-blue">{x}</span><br />
                        <span className="text-sm">{data.summary[data.display[x]]}</span>
                    </p>
                )}
            </div>}
            {data && <CommonTooltip className="ml-0.5 pt-2" width="w-52" bottomLeft={true}>
                <IoIosInformationCircleOutline className="text-sk-blue" />
                <p className="w-full list-decimal ml-2 text-center">
                    Last refreshed at<br />{data.updated}
                </p>
            </CommonTooltip>}
        </div>
    )
}

export default SummaryMetrics