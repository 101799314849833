import React, { useEffect, useState } from 'react'

const Dropdown = (props) => {
    const [isShown, setIsShown] = useState(false)

    function toggle(e, forceSet) {
        e.stopPropagation()
        if (e.currentTarget.contains(e.relatedTarget)) return true
        if (forceSet !== undefined) setIsShown(forceSet)
        else setIsShown(!isShown)
    }

    useEffect(() => {
        if (props.rotateElementId) {
            if (isShown) document.getElementById(props.rotateElementId).classList.add("rotate-180")
            else document.getElementById(props.rotateElementId).classList.remove("rotate-180")
        }
    }, [isShown, props.rotateElementId])

    return (
        <div className={"relative " + (props.className || "")} onClick={toggle} onBlur={(e) => toggle(e, false)} tabIndex={0}>
            <div id={props.id} className="cursor-pointer">
                {props.children[0]}
            </div>
            <div id={props.dropdownId} className={"z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 mt-2 absolute " + (props.alignLeft ? "left-0" : "right-0") + " " + (isShown ? "" : "hidden")}>
                <ul className="py-2 text-sm font-medium">
                    {props.children.map((x, i) =>
                        i > 0 && <li key={i} className="cursor-pointer">{x}</li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default Dropdown