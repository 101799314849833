const monthLookUp = {
    0: "Jan", 1: "Feb", 2: "Mar", 3: "Apr", 4: "May", 5: "Jun", 6: "Jul", 7: "Aug", 8: "Sep", 9: "Oct", 10: "Nov", 11: "Dec"
}

const utils = {
    formatINR: function (valueIn, digitsAfterDecimal = 0, includeRupeeSymbol = 0, ) {
        if (valueIn === "") return ""

        if (typeof valueIn !== "string") valueIn = valueIn.toString()
        valueIn = valueIn.replace(/[,/d]/g, "")

        let valueOut = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: digitsAfterDecimal
        }).format(valueIn).substring(includeRupeeSymbol ? 0 : 1)

        return valueOut
    },
    diffInDays: function (d1, d2) {
        return Math.floor(((new Date(d1)) - (new Date(d2))) / (1000 * 60 * 60 * 24))
    },
    formatDate: function (ISODateString) {
        const date = new Date(ISODateString)
        return date.getDate().toString().padStart(2, "0") + " " + monthLookUp[date.getMonth()] + ", " + date.getFullYear()
    },
    formatInsuranceAge: function(age) {
        if (age < 1) return Math.round(age * 100) + " months"
        else if (age === 1) return age + " year"
        else return age + " years"
    }
}

export default utils