import callapi from "./callapi"
import endpoints from "../config/endpoints"

const application = {
    new: async function (data) {
        const { first_name, last_name, mobile, dob, gender, monthly_income, email, amount } = data

        // validations
        if (!first_name || !last_name || !mobile || !dob || !gender || !monthly_income || !email || !amount) return { error: "Please enter all required fields." }

        return await callapi({
            url: endpoints.application.new,
            method: "post",
            data
        })
    },
    moreDetails: async function (applicationNumber, data) {
        const { pan, work_pincode, home_pincode, home_type, employment_type } = data

        // validations
        if (!pan || !work_pincode || !home_pincode || !home_type || !employment_type) return { error: "Please enter all required fields." }

        return await callapi({
            url: endpoints.application.moreDetails(applicationNumber),
            method: "post",
            data
        })
    },
    getOffers: async function (applicationNumber) {
        return await callapi({
            url: endpoints.application.getOffers(applicationNumber),
            method: "post"
        })
    },
    sendLink: async function (applicationNumber, data) {
        const { selected_offer } = data

        // validations
        if (!applicationNumber) return { error: "Unknown application!" }
        if (!selected_offer) return { error: "Please select an offer." }
        // if (!num_travellers || !destination_type || !source || !destination || !includes_flights || !round_trip || !start_date || (round_trip === "Yes" && !return_date)) return { error: "Please enter all itinerary details." }

        return await callapi({
            url: endpoints.application.sendLink(applicationNumber),
            method: "post",
            data
        })
    },
    getStatus: async function (applicationNumber) {
        return await callapi({
            method: "get",
            url: endpoints.application.getStatus(applicationNumber)
        })
    },
    update: async function(applicationNumber, data) {
        return await callapi({
            method: "post",
            url: endpoints.application.update(applicationNumber),
            data
        })
    }
}

export default application