import React from "react"
import { FaCircleInfo } from "react-icons/fa6"

const Badges = {
    General: ({ icon, title, text, className }) => {
        return (
            <div className={'inline-block rounded-lg overflow-hidden border border-sk-blue ' + className}><p className="flex justify-stretch flex-row gap-2 text-xs font-medium"><span className='p-2 bg-sk-blue flex items-center gap-1 text-white'>{icon || <FaCircleInfo />} {title}</span><span className='p-2'>{text}</span></p></div>
        )
    }
}

export default Badges