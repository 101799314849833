import MX from 'mixpanel-browser'

let init = false

const MixPanel = {
    init: () => {
        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            MX.init(process.env.REACT_APP_MIXPANEL_TOKEN, { track_pageview: "url-with-path" })
            MX.set_config({ persistence: "localStorage" })
            init = true
        }
    },
    identify: id => init && MX.identify(id),
    track: (name, props) => {
        try {
            init && MX.track(name, props)
        } catch (e) { }
    },
    people: {
        set: props => init && MX.people.set(props),
        set_once: props => init && MX.people.set_once(props),
        increment: type => init && MX.people.increment(type)
    },
    reset: _ => init && MX.reset(),
    types: {
        general: {
            api: {
                success: "api_success",
                error: "api_error" // status_code, url
            }
        },
        auth: {
            login: "portal_auth_login",
            logout: "portal_auth_logout", // not implemented
            forgot_password: {
                otp_generated: "portal_auth_forgot_otp_generated",
                resend_otp: "portal_auth_forgot_resend_otp",
                enter_otp: "portal_auth_forgot_enter_otp",
                change_password: "portal_auth_forgot_change_pass"
            }
        },
        agent: {
            application: {
                form1: "agent_app_form1",
                form2: "agent_app_form2",
                offers_shown: "agent_app_offers_shown",
                rejected: "agent_app_rejected",
                link_shared: "agent_app_link_shared"
            },
            insurance: {
                search: "agent_ins_search",
                view_cover: "agent_ins_view_cover", // id
                email_quotes: "agent_ins_email_quotes",
                select_premium: "agent_ins_select_premium",
                copy_details_from_p1: "agent_ins_copy_details_from_p1",
                passenger_details: "agent_ins_passenger_details",
                review_payment: "agent_ins_review_payment",
                policy_generated: "agent_ins_policy_generated",
                policy_error: "agent_ins_policy_error",
                policy_download: "agent_ins_policy_download",
                invoice_download: "agent_ins_invoice_download",
                group_enquiry: "agent_ins_group_enquiry",
                passport_scan: "agent_ins_passport_scan",
                promotion_applied: "agent_ins_promotion_applied"
            },
            payment: {
                generate_link: "agent_pay_generate_link"
            },
            transactions: {
                applications: "agent_txn_applications",
                appliation_filter: "agent_txn_applications_filter", // not implemented
                application_detail: "agent_txn_application_detail", // id
                application_detail_action: "agent_txn_application_detail_action", // type
                insurance: "agent_txn_insurance",
                insurance_filter: "agent_txn_insurance_filter", // not implemented
                insurance_detail: "agent_txn_insurance_detail", // id
                insurance_detail_action: "agent_txn_insurance_detail_action", // type
                wallet: "agent_txn_wallet",
                wallet_filter: "agent_txn_wallet_filter", // not implemented
                wallet_export: "agent_txn_wallet_export", // not implemented
                payment: "agent_txn_payment",
                payment_detail: "agent_txn_payment_detail"
            },
            emi: {
                calculator_offers: "agent_emi_calculator_offers",
                calculator_no_offers: "agent_emi_calculator_no_offers",
            },
            wallet: {
                add_open: "agent_wal_add_open", // type
                rzp_open: "agent_wal_rzp_open",
                rzp_error: "agent_wal_rzp_error",
                add_success: "agent_wal_add_success"
            },
            rm: {}
        },
        customer: {
            auth: {
                enter_mobile: "customer_auth_enter_mobile",
                enter_otp: "customer_auth_enter_otp",
                resend_otp: "customer_auth_resend_otp",
            },
            application: {
                form1: "customer_app_form1",
                form2: "customer_app_form2",
                offers_shown: "customer_app_offers_shown",
                rejected: "customer_app_rejected",
                redirect_to_lender: "customer_app_redirect_to_lender",
                expired: "customer_app_expired",
                in_progress: "customer_app_in_progress",
                session_expired: "customer_app_session_expired",
                api_error: "customer_app_api_error",
                internal_error: "customer_app_internal_error",
                pfee_rzp_open: "customer_app_pfee_rzp_open",
                pfee_rzp_error: "customer_app_pfee_rzp_error",
                pfee_success: "customer_app_pfee_success",
                complete: "customer_app_complete"
            }
        },
        backoffice: {
            dashboard: {},
            partner: {
                filter: "bo_partner_filter", // not implemented
                export: "bo_partner_export", // not implemented
                detail: "bo_partner_detail", // id
                update: "bo_partner_detail_update", // type
                add: "bo_partner_add",
                bulk_update: "bo_partner_bulk_update",
                newlead: "bo_partner_newlead_clicked"

            },
            agent: {
                filter: "bo_agent_filter", // not implemented
                export: "bo_agent_export", // not implemented
                detail: "bo_agent_detail", // id
                action: "bo_agent_detail_action", // type
                update: "bo_agent_detail_update", // type
                add: "bo_agent_add",
            },
            tnpl: {
                filter: "bo_tnpl_filter", // not implemented
                export: "bo_tnpl_export", // not implemented
                detail: "bo_tnpl_detail", // id
                action: "bo_tnpl_detail_action", // type
            },
            ti: {
                filter: "bo_ti_filter", // not implemented
                export: "bo_ti_export", // not implemented
                detail: "bo_ti_detail", // id
                action: "bo_ti_detail_action", // type
            },
            wallet: {
                transactions: {
                    filter: "bo_wallet_txns_filter", // not implemented
                    export: "bo_wallet_txns_export", // not implemented
                },
                requests: {
                    filter: "bo_wallet_requests_filter", // not implemented
                    export: "bo_wallet_requests_export", // not implemented
                    action: "bo_wallet_requests_action", // type
                }
            },
            user_management: {
                filter: "bo_user_mgmt_filter", // not implemented
                update: "bo_user_mgmt_update", // type
                add: "bo_user_mgmt_add",
            }
        },
        onboarding: {
            validated: "agent_onboarding_form1_validated",
            otp_sent: "agent_onboarding_otp_sent",
            otp_entered: "agent_onboarding_otp_entered",
            onboarded: "agent_onboarding_successful"
        },
        landing: {
            working_capital: "work_capital_lead_created"
        }
    }
}

export default MixPanel